import BasePlugin from '../BasePlugin'

export default class ChangeResponsibleAdjustmentRequest extends BasePlugin {
  async execute () {
    let DMKIEmployee = JSON.parse(this.context.getParentContext().getCard().getRecordData().attr_4890_)
    let DIPIAEmployee = JSON.parse(this.context.getParentContext().getCard().getRecordData().attr_4963_)
    let userId = JSON.parse(atob(localStorage.getItem('user-token').split('.')[1])).user.id
    let newResponsibleEmployeeId = this.context.getModel().responsible
    let me = this

    if (DMKIEmployee[0].id === userId || DIPIAEmployee[0].id === userId) {
      let adjustmentRequest = this.context.getModel()
      let adjustmentRequestData = {
        'adjustment_request_id': adjustmentRequest.id,
        'new_responsible_employee_id': newResponsibleEmployeeId
      }
      this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddApprovalHistoryRecordAfterAdjustmentRequestChangingResponsibleCommand`,
        adjustmentRequestData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      this.context.$msgbox.close()
      me.context.$msgbox({
        type: 'info',
        message: 'Назначен новый ответственный'
      })
      this.context.getParentContext().getCard().$emit('cancelChanges')
    } else {
      this.context.$msgbox.close()
      me.context.$msgbox({
        type: 'info',
        message: 'Переназначить ответственного в процедуре согласования может только ответственный ДИПиА/ДМиКИ'
      })
    }
  }
}
